import api from "../utils/api";

export const addMessage = async (question, chat, attachment = null) => {
  const endpoint = `cognitive/chats/${chat}/`;

  const formData = new FormData();
  formData.append("message", question);

  if (attachment != null) {
    formData.append("file", attachment);
  }

  if (attachment != null) {
    const resp = await api.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return resp;
  } else {
    const resp = await api.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return resp;
  }
};

const MessagesApi = {
  addMessage,
};

export default MessagesApi;
