import { useState, useEffect, useRef } from "react";
import { Settings } from "lucide-react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

export function ChatBubbles({ messages, isChatVisible }) {
  ChatBubbles.propTypes = {
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
    resetMsg: PropTypes.func.isRequired,
  };

  useEffect(() => {
    const chatContainer = document.getElementById("chat-container");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [messages]);

  return (
    <div className="fixed right-0 top-2 bottom-3 w-3/12 mr-2">
      <div
        className={`h-full overflow-y-auto pb-16 transition-transform duration-300 ${
          isChatVisible ? "translate-x-0" : "translate-x-full"
        } scrollbar-hidden`}
        id="chat-container"
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.role === "user" ? "justify-end" : "justify-start"
            }`}
          >
            {message.type === "user" ? (
              <div className="w-11/12">
                <div
                  className={`p-3 m-2 rounded-lg bg-gray-900 pl-5 ${
                    index === messages.length - 1 ? "animate-fadeIn" : ""
                  }`}
                >
                  <ReactMarkdown>{message.content}</ReactMarkdown>
                </div>
              </div>
            ) : (
              <div className="w-11/12">
                <div
                  className={`p-3 m-2 rounded-lg bg-gray-800 ${
                    index === messages.length - 1 ? "animate-fadeIn" : ""
                  }`}
                >
                  <ReactMarkdown>{message.content}</ReactMarkdown>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
