import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import LaunchRocket from "./components/LaunchRocket";
import Team from "./components/Team.jsx";

const isMobile = window.matchMedia("(max-width: 768px)").matches;
if (isMobile) {
  window.location.href = "https://cs.pyxis.tech/";
} else {
  const reactRoot = document.createElement("div");
  reactRoot.id = "react-root";
  document.body.appendChild(reactRoot);

  ReactDOM.createRoot(reactRoot).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  const rocketAnimation = document.createElement("div");
  rocketAnimation.id = "rocketAnimation";
  document.body.appendChild(rocketAnimation);

  ReactDOM.createRoot(document.getElementById("rocketAnimation")).render(
    <React.StrictMode>
      <LaunchRocket />
    </React.StrictMode>
  );

  const teamDiv = document.createElement("div");
  teamDiv.id = "team";
  document.body.appendChild(teamDiv);

  ReactDOM.createRoot(document.getElementById("team")).render(
    <React.StrictMode>
      <Team />
    </React.StrictMode>
  );
}
