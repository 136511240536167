import api from "../utils/api";

export const addQuestion = async (question, retrieval, attachment) => {
  const endpoint = `cognitive/assistants/${retrieval}/chats/`;

  const formData = new FormData();
  formData.append("message", question);

  if (attachment != null) {
    formData.append("file", attachment);
  }

  if (attachment != null) {
    const resp = await api.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return resp;
  } else {
    const resp = await api.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return resp;
  }
};

export const getChat = async (id) => {
  const { data } = await api.get(`cognitive/chats/${id}/`);
  return data;
};

export const getChats = async (retrieval) => {
  const { data } = await api.get(`cognitive/assistants/${retrieval}/chats/`);
  return data;
};


const QuestionsApi = {
  addQuestion,
  getChat,
  getChats,
};

export default QuestionsApi;
