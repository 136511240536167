import { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import rocketAnimation from "../assets/animation/rocket.json";
import "../styles/LaunchRocket.css";

const LaunchRocket = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const launchRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef(null);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          timeoutRef.current = setTimeout(() => {
            setIsVisible(true);
            observer.disconnect();
          }, 1000);
        }
      },
      { threshold: 0.1 }
    );
    if (launchRef.current) {
      observer.observe(launchRef.current);
    }
    return () => {
      if (observer && observer.disconnect) observer.disconnect();
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      key={animationComplete ? "complete" : "incomplete"}
      className="animation-container"
      ref={launchRef}
    >
      {isVisible && !animationComplete && (
        <div className="animation-wrapper">
          <Lottie
            animationData={rocketAnimation}
            loop={false}
            speed={0.7}
            onComplete={handleAnimationComplete}
          />
        </div>
      )}
      {isVisible && animationComplete && (
        <div className="text-6xl text-center text-white font-semibold">
          <div className="mb-11">Going live</div>
          <div className="mb-11">will take between</div>
          <div className="mb-11 text-8xl">2 - 4</div>
          <div className="">months</div>
        </div>
      )}
    </div>
  );
};

export default LaunchRocket;
