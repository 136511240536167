import React from "react";

import lu from "../assets/images/lu.jpeg";
import jairo from "../assets/images/jairo.jpeg";
import gabi from "../assets/images/gabi.jpeg";
import nico from "../assets/images/nico.png";
import vicky from "../assets/images/vicky.jpeg";
import alcan from "../assets/images/alcan.jpeg";
import diego from "../assets/images/diego.jpeg";
import seba from "../assets/images/seba.jpeg";
import rodri from "../assets/images/rodri.jpg";
import nacho from "../assets/images/ignacio.jpeg";

const TeamMember = ({ name, role, description, image }) => (
  <div className="flex flex-col items-center text-center">
    <div className="relative w-32 h-32 mb-4">
      <img
        src={image}
        alt={name}
        className="rounded-full object-cover w-full h-full"
      />
    </div>
    <h3 className="text-lg font-semibold">{name}</h3>
    <p className="text-gray-500 text-sm">{role}</p>
    {description && (
      <p className="text-gray-300 text-sm max-w-md">{description}</p>
    )}
  </div>
);

const Team = () => {
  const founders = [
    {
      name: "Rodrigo Sastre",
      role: "Co-founder",
      description:
        "9 years in the industry, leading teams in mission-critical projects.",
      image: rodri,
    },
    {
      name: "Ignacio Sastre",
      role: "Co-founder",
      description:
        "Professor and researcher in the Natural Language Processing group, InCo, Faculty of Engineering UDELAR.",
      image: nacho,
    },
  ];

  const teamMembers = [
    {
      name: "Jairo Bonanata",
      role: "AI Senior Dev",
      image: jairo,
    },
    {
      name: "Lucia Bouza",
      role: "AI Solutions Architect",
      image: lu,
    },
    {
      name: "Gabriel Obaldía",
      role: "AI Senior Dev",
      image: gabi,
    },
    {
      name: "Nicolás Fernandez",
      role: "UI/UX Developer",
      image: nico,
    },
    {
      name: "Virginia de los Santos",
      role: "Quality Assurance",
      image: vicky,
    },
    {
      name: "Agustín Anastasía",
      role: "Backend Developer",
      image: alcan,
    },
  ];

  const boardMembers = [
    {
      name: "Diego Sastre",
      role: "Board Member",
      image: diego,
    },
    {
      name: "Sebastián García",
      role: "Board Member",
      image: seba,
    },
  ];

  return (
    <div className="min-h-screen text-white mx-24">
      <div className="mb-24 ml-24">
        <h6 className="sub-title mb-15">Our</h6>
        <h3 className="texto-3em negrita">Team:</h3>
      </div>
      <div className="flex flex-col lg:flex-row gap-12">
        <div className="lg:w-1/3 space-y-12">
          {founders.map((founder) => (
            <TeamMember key={founder.name} {...founder} />
          ))}
        </div>

        <div className="lg:w-2/3 px-10">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-24">
            {teamMembers.map((member) => (
              <TeamMember key={member.name} {...member} />
            ))}
          </div>

          <div className="grid grid-cols-2 gap-8">
            {boardMembers.map((board) => (
              <TeamMember key={board.name} {...board} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
