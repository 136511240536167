import { useState, useEffect, useRef } from "react";
import { Send, Settings } from "lucide-react";
import PropTypes from "prop-types";

const placeholders = [
  "I'm a partner at a law firm, how could CogSol help me?",
  "I own a supermarket, how does this benefit me?",
  "Why choose CogSol over so many Gen AI tools out there?",
  "How can I learn more about CogSol?",
  "I'm a small business owner, what value does CogSol bring to my company?",
  "As a CEO of a startup, why should I invest in CogSol's technology?",
  "Can CogSol be tailored to meet the needs of my specific industry?",
  "What are the key features of CogSol's AI technology?",
  "How can I get started with CogSol?",
];

export function Prompt({
  onSubmit,
  inputValue,
  setInputValue,
  resetMsg,
  isConfigOpen,
  setIsConfigOpen,
  isChatVisible,
  setIsChatVisible,
}) {
  const [placeholderIndex, setPlaceholderIndex] = useState(
    Math.floor(Math.random() * placeholders.length)
  );
  const inputRef = useRef(null);
  const configRef = useRef(null);

  Prompt.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    setInputValue: PropTypes.func.isRequired,
  };

  const [shuffledPlaceholders] = useState(() =>
    [...placeholders].sort(() => Math.random() - 0.5)
  );

  useEffect(() => {
    inputRef.current?.focus();

    const intervalId = setInterval(() => {
      setPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % shuffledPlaceholders.length
      );
    }, 4500);

    return () => clearInterval(intervalId);
  }, [shuffledPlaceholders.length]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configRef.current && !configRef.current.contains(event.target)) {
        setIsConfigOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [configRef]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      onSubmit();
      setInputValue("");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="fixed bottom-8 left-1/2 transform -translate-x-1/2 w-full max-w-4xl px-4"
    >
      <div className="relative flex items-center">
        <button
          type="button"
          onClick={() => setIsConfigOpen(!isConfigOpen)}
          className="mr-4 p-2 bg-gray-500 text-white rounded-full shadow-lg hover:bg-gray-600 hover:border-gray-600 transition-colors"
        >
          <Settings size={24} />
        </button>
        <input
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholders[placeholderIndex]}
          className="w-full pr-20 rounded-full shadow-lg border-2 border-gray-200 focus:border-gray-600 transition-colors h-14 text-lg pl-6 outline-none text-gray-700 hover:border-gray-600"
        />
        <button
          type="submit"
          className="absolute right-2 rounded-full w-15 h-10 bg-gray-500 hover:bg-gray-600 hover:border-gray-600 flex items-center justify-center transition-colors"
        >
          <Send className="h-5 w-5 text-gray-100" />
          <span className="sr-only">Send</span>
        </button>
      </div>
      {isConfigOpen && (
        <div
          ref={configRef}
          className="fixed bottom-16 left-4 p-4 bg-white rounded-lg shadow-lg animate-fadeIn"
        >
          <h3 className="font-bold mb-3 text-black text-right">
            Chat Configuration
          </h3>
          <label className="flex items-center justify-start text-black">
            <input
              type="checkbox"
              checked={isChatVisible}
              onChange={() => {
                setIsChatVisible((prev) => !prev), setIsConfigOpen(false);
              }}
              className="mr-2 bg-gray-50 hover:bg-gray-200 checked:bg-gray-700 checked:hover:bg-gray-500 focus:outline-none rounded"
            />
            Show Chat
          </label>
          <div className="flex justify-start">
            <button
              onClick={() => {
                resetMsg();
                setIsConfigOpen(false);
              }}
              className="mt-3 px-2 py-1 text-xs font-light text-black rounded border border-black transition-colors"
            >
              New Chat
            </button>
          </div>
        </div>
      )}
    </form>
  );
}
