import { useState, useEffect } from "react";
//import OldSite from "./components/OldSite";
import { Prompt } from "./Prompt";
import { ChatBubbles } from "./ChatBubble";

import { addMessage } from "../api/MessagesApi";
import { addQuestion } from "../api/QuestionApi";
import { getRetrieval } from "../api/RetrievalsApi";

import parameters from "../utils/parameters";
const {
  retrieval: { retrievalId },
} = parameters;

const MAX_MSG_LENGTH = 1000;
const MAX_RESPONSES = 10;
const MAX_RESP_STATUS = 300;

function ChatConfig() {
  const [showPrompt, setShowPrompt] = useState(false);
  const [messages, setMessages] = useState([]);

  const [question, setQuestion] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [maxMsgLength, setMaxMsgLength] = useState(MAX_MSG_LENGTH);
  const [maxResponses, setIMaxResponses] = useState(MAX_RESPONSES);
  const [cantResponses, setcantResponses] = useState(1);
  const [endedStatus, setEndedStatus] = useState(0);

  const [ended, setEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(true);

  useEffect(() => {
    if (question == null) {
      const getInfoRetrieval = async (retrievalId) => {
        try {
          const data = await getRetrieval(retrievalId);
          setMaxMsgLength(data.max_msg_length);
          console.log("Max msg length: ", maxMsgLength);
          setIMaxResponses(data.max_responses);
          console.log("Max responses: ", maxResponses);
        } catch (error) {
          console.log(error);
        }
      };
      getInfoRetrieval(retrievalId);
    }
  }, [messages, question, maxMsgLength, maxResponses]);

  const requestStatus = (status) => {
    if (status > MAX_RESP_STATUS) {
      setEndedStatus(status);
      console.log("Ended: ", ended);
    }
  };

  //////////////////////////////////////////////////////////////////////////
  const sendMessage = async () => {
    if (inputValue.trim() === "") return;
    setEndedStatus(0);

    // USER MESSAGE
    const userMessage = {
      content: inputValue,
      role: "user",
      usr_msg_num: cantResponses,
    };

    setcantResponses(cantResponses + 1);
    setMessages([...messages, userMessage]);
    setInputValue("");
    setIsLoading(true);
    console.log(isLoading);

    // LOADING MESSAGE
    const loadingMessage = {
      content: "Thinking...",
      role: "assistant",
      loading: true,
    };

    setMessages((prevMessages) => [...prevMessages, loadingMessage]);

    /// send information to API and update messages and state
    try {
      // API call
      let response = null;
      if (question != null) {
        response = await addMessage(inputValue, question.id);
      } else {
        response = await addQuestion(inputValue, retrievalId);
      }
      // see if errors
      if (response.status) {
        requestStatus(response.status);
        if (endedStatus > MAX_RESP_STATUS) {
          setEnded(true);
        } else {
          setEnded(response.data.ended);
        }
      }
      // set Question chat
      setQuestion(response.data);

      // Update messages with attachment
      const updatedMessages = response.data.messages.map((msg, index) => {
        if (index < messages.length && messages[index].attachment) {
          return {
            ...msg,
            attachment: messages[index].attachment,
          };
        }
        return msg;
      });

      setMessages(updatedMessages);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  //////////////////////////////////////////////////////////////////////////

  const handleResetMessages = () => {
    setMessages([]);
    setQuestion(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPrompt(true);
    }, 2800);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/*  <OldSite />  */}
      {showPrompt && (
        <>
          <Prompt
            onSubmit={sendMessage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            isConfigOpen={isConfigOpen}
            setIsConfigOpen={setIsConfigOpen}
            resetMsg={handleResetMessages}
            isChatVisible={isChatVisible}
            setIsChatVisible={setIsChatVisible}
          />
          <ChatBubbles messages={messages} isChatVisible={isChatVisible} />
        </>
      )}
    </>
  );
}

export default ChatConfig;
