import "./App.css";
import ChatConfig from "./components/ChatConfig";

function App() {
  return (
    <>
      <ChatConfig />
    </>
  );
}

export default App;
